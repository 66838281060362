import cx from 'classnames';
import {
  EmojiExcitedFlatIcon,
  EmojiExhaustedFlatIcon,
  EmojiHappyFlatIcon,
  EmojiNeutralFlatIcon,
  EmojiUnhappyFlatIcon,
  EmojiExcitedLineIcon,
  EmojiExhaustedLineIcon,
  EmojiHappyLineIcon,
  EmojiNeutralLineIcon,
  EmojiUnhappyLineIcon,
  ErrorIcon,
  CheckMarkIcon,
} from '../icons';
import { Moods } from './MoodModal';

const getFlatIconForMood = (mood: Moods) => {
  if (mood === 'excited') return <EmojiExcitedFlatIcon />;
  if (mood === 'happy') return <EmojiHappyFlatIcon />;
  if (mood === 'neutral') return <EmojiNeutralFlatIcon />;
  if (mood === 'unhappy') return <EmojiUnhappyFlatIcon />;
  if (mood === 'exhausted') return <EmojiExhaustedFlatIcon />;
  if (mood === 'done') return <CheckMarkIcon size={48} color="#34C759" />;
  if (mood === 'pending') return <ErrorIcon size={48} />;
  return null;
};

const getLinesIconForMood = (mood: Moods) => {
  if (mood === 'excited') return <EmojiExcitedLineIcon />;
  if (mood === 'happy') return <EmojiHappyLineIcon />;
  if (mood === 'neutral') return <EmojiNeutralLineIcon />;
  if (mood === 'unhappy') return <EmojiUnhappyLineIcon />;
  if (mood === 'exhausted') return <EmojiExhaustedLineIcon />;
  if (mood === 'done') return <CheckMarkIcon size={48} color="white" />;
  if (mood === 'pending') return <ErrorIcon size={48} color="white" />;
  return null;
};

type MoodButtonProps = {
  isSelected?: boolean;
  moodName: Moods;
  onClick?: (mood: Moods) => void;
};

export function MoodButton({
  isSelected = false,
  moodName,
  onClick,
}: MoodButtonProps) {
  const wrapperClasses = cx(
    'mood-button__wrapper',
    isSelected && 'mood-button__selected'
  );

  return (
    <button className={wrapperClasses} onClick={() => onClick?.(moodName)}>
      <span className="mood-button__lines">
        {getLinesIconForMood(moodName)}
      </span>
      <span className="mood-button__flat">{getFlatIconForMood(moodName)}</span>
    </button>
  );
}
