import { IconProps } from '.';

export function CheckMarkIcon({ color = '#AAAAAA', size = 32 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.0728 10.9231C23.561 11.4114 23.561 12.2029 23.0728 12.6909L14.687 21.0769C14.1987 21.5649 13.4075 21.5649 12.9192 21.0769L8.92725 17.0847C8.43896 16.5967 8.43896 15.8052 8.92725 15.3171C9.41528 14.8289 10.2068 14.8289 10.6948 15.3171L13.803 18.4253L21.3049 10.9231C21.7932 10.4351 22.5847 10.4351 23.0728 10.9231ZM32 16C32 24.844 24.8428 32 16 32C7.15601 32 0 24.8428 0 16C0 7.15601 7.15723 0 16 0C24.844 0 32 7.15723 32 16ZM29.5 16C29.5 8.53784 23.4612 2.5 16 2.5C8.53784 2.5 2.5 8.53882 2.5 16C2.5 23.4622 8.53882 29.5 16 29.5C23.4622 29.5 29.5 23.4612 29.5 16Z"
        fill={color}
      />
    </svg>
  );
}
