import { IconProps } from '.';

export function PlayIcon({ color = '#5AC8FA', size = 32 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42.5559 30.7488L29.2665 21.0884C28.7788 20.7349 28.1314 20.6816 27.5968 20.9564C27.058 21.229 26.7215 21.7827 26.7215 22.3811V41.6955C26.7215 42.3004 27.058 42.852 27.5968 43.1246C27.8247 43.2396 28.0739 43.2971 28.3252 43.2971C28.6531 43.2971 28.9854 43.1927 29.2665 42.9861L42.5559 33.3343C42.9755 33.0255 43.2204 32.5484 43.2204 32.0415C43.2225 31.5261 42.9712 31.0512 42.5559 30.7488Z"
        fill={color}
      />
      <path
        d="M32.0011 0.00525594C14.3244 0.00525594 0 14.3297 0 32.0063C0 49.6765 14.3244 63.9967 32.0011 63.9967C49.6734 63.9967 64 49.6744 64 32.0063C64.0021 14.3297 49.6734 0.00525594 32.0011 0.00525594ZM32.0011 58.6575C17.2805 58.6575 5.34558 46.729 5.34558 32.0063C5.34558 17.29 17.2805 5.34658 32.0011 5.34658C46.7195 5.34658 58.6523 17.2879 58.6523 32.0063C58.6544 46.729 46.7195 58.6575 32.0011 58.6575Z"
        fill={color}
      />
    </svg>
  );
}
