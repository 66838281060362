import React, { useState } from 'react';
import { v4 } from 'uuid';
import { useAppContext } from '../App';
import { TodoItemType } from './TodoItem';
import TodoList from './TodoList';

type Props = {
  isTimerActive: boolean;
};

function TodoForm({ isTimerActive }: Props) {
  const [newTodo, setNewTodo] = useState<string>('');
  const { todos, addTodo, updateTodo, removeTodo, startItem, currentTodo } =
    useAppContext();

  if (isTimerActive) {
    return null;
  }

  const addNewTodoItem = () => {
    addTodo(getNewTodoItem());
    setNewTodo('');
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setNewTodo(e.target.value);
  };

  const handleInputKeyDown = (e: React.KeyboardEvent): void => {
    if (e.key === 'Enter' && newTodo) {
      addNewTodoItem();
    }
  };

  const getNewTodoItem = (): TodoItemType => {
    return { id: v4(), text: newTodo, type: 'task' };
  };

  return (
    <div className="container mb-8">
      <TodoList
        title="To Do"
        todos={todos}
        updateTodo={updateTodo}
        removeTodo={removeTodo}
        startItem={startItem}
        hasCurrentItem={Boolean(currentTodo)}
      >
        <div className="flex flex-row border-b py-2">
          <input
            className="focus:outline-none flex-grow"
            onChange={handleInputChange}
            onKeyDown={handleInputKeyDown}
            placeholder="Add A Task"
            type="text"
            value={newTodo}
          />
          <button
            className="ml-3 text-base flex-grow-0"
            disabled={!newTodo}
            onClick={() => addNewTodoItem()}
            style={{ color: '#007AFF' }}
          >
            Create
          </button>
        </div>
      </TodoList>
    </div>
  );
}

export default TodoForm;
