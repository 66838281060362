import React, { ReactNode } from 'react';
import TodoItem, { TodoItemType } from './TodoItem';

type Props = {
  children?: ReactNode;
  emptyListText?: string;
  hasCurrentItem?: boolean;
  isTimerActive?: boolean;
  removeTodo?: (todoItem: TodoItemType) => void;
  startItem?: (todoItem: TodoItemType) => void;
  title: string;
  todos: TodoItemType[];
  updateTodo?: (todoItem: TodoItemType) => void;
};

function TodoList(props: Props) {
  const {
    children,
    emptyListText,
    hasCurrentItem,
    isTimerActive,
    removeTodo,
    startItem,
    title,
    todos,
    updateTodo,
  } = props;

  if (isTimerActive) {
    return null;
  }

  return (
    <div>
      <h2 className="text-xl font-bold text-left mb-4">{title}</h2>
      {children}
      {todos.map((todo) => (
        <TodoItem
          key={todo.id}
          todo={todo}
          updateTodo={updateTodo}
          removeTodo={removeTodo}
          startItem={startItem}
          hasCurrentItem={hasCurrentItem}
        />
      ))}
      {todos.length === 0 && emptyListText && (
        <p className="text-base text-gray-500 text-left">{emptyListText}</p>
      )}
    </div>
  );
}

export default TodoList;
