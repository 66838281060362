import { useAppContext } from '../App';
import TodoForm from './TodoForm';
import TodoList from './TodoList';

function Lanes() {
  const { finishedTodos } = useAppContext();

  const isTimerActive = false;

  return (
    <div className="lanes__wrapper container mx-auto">
      <TodoForm isTimerActive={isTimerActive} />
      <TodoList
        emptyListText="No completed items to show :("
        todos={finishedTodos}
        isTimerActive={isTimerActive}
        title="Done"
      />
    </div>
  );
}

export default Lanes;
