import { IconProps } from '.';

export function ErrorIcon({ color = '#FF3B30', size = 32 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 0C7.15675 0 0 7.15606 0 16C0 24.8433 7.15606 32 16 32C24.8433 32 32 24.8439 32 16C32 7.15669 24.8439 0 16 0ZM16 29.5C8.5385 29.5 2.5 23.4621 2.5 16C2.5 8.53844 8.53794 2.5 16 2.5C23.4615 2.5 29.5 8.53794 29.5 16C29.5 23.4616 23.4621 29.5 16 29.5Z"
        fill={color}
      />
      <path
        d="M21.4741 19.7064L17.7677 16L21.4741 12.2936C21.9622 11.8055 21.9623 11.0141 21.4741 10.5259C20.9859 10.0377 20.1944 10.0377 19.7064 10.5259L16 14.2322L12.2935 10.5259C11.8055 10.0377 11.0139 10.0377 10.5258 10.5259C10.0376 11.0141 10.0376 11.8055 10.5258 12.2936L14.2322 16L10.5258 19.7064C10.0376 20.1946 10.0376 20.986 10.5258 21.4741C11.0141 21.9624 11.8055 21.9622 12.2935 21.4741L16 17.7677L19.7064 21.4741C20.1944 21.9622 20.9859 21.9623 21.4741 21.4741C21.9623 20.9859 21.9623 20.1945 21.4741 19.7064Z"
        fill={color}
      />
    </svg>
  );
}
