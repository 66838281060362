import { IconProps } from '.';

export function PauseIcon({ color = '#5AC8FA', size = 32 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2_156)">
        <path
          d="M32.0001 64C49.6731 64 64.0002 49.6731 64.0002 31.9999C64.0002 14.3267 49.6731 0 32.0001 0C14.3272 0 0 14.3269 0 32.0001C0 49.6733 14.3269 64 32.0001 64ZM32.0001 5.11994C46.8213 5.11994 58.8801 17.1787 58.8801 31.9999C58.8801 46.821 46.8213 58.8798 32.0001 58.8798C17.179 58.8798 5.12018 46.821 5.12018 31.9999C5.12018 17.1787 17.1776 5.11994 32.0001 5.11994Z"
          fill={color}
        />
        <path
          d="M26.0532 47.0938C27.8208 47.0938 29.2533 45.6615 29.2533 43.8937V19.5737C29.2533 17.8061 27.821 16.3737 26.0532 16.3737C24.2856 16.3737 22.8531 17.8059 22.8531 19.5737V43.8937C22.8531 45.6615 24.2854 47.0938 26.0532 47.0938Z"
          fill={color}
        />
        <path
          d="M38.8531 47.0938C40.6207 47.0938 42.0532 45.6615 42.0532 43.8937V19.5737C42.0532 17.8061 40.6209 16.3737 38.8531 16.3737C37.0852 16.3737 35.6532 17.8061 35.6532 19.5737V43.8937C35.6532 45.6615 37.0855 47.0938 38.8531 47.0938Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2_156">
          <rect width="64" height="64" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
