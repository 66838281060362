import { IconProps } from '.';

export function EmojiUnhappyLineIcon({
  color = 'white',
  size = 48,
}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 0C10.7663 0 0 10.7663 0 24C0 37.2337 10.7663 48 24 48C37.2337 48 48 37.2337 48 24C48 10.7663 37.2337 0 24 0ZM24 45.1875C12.3172 45.1875 2.8125 35.6828 2.8125 24C2.8125 12.3172 12.3172 2.8125 24 2.8125C35.6828 2.8125 45.1875 12.3172 45.1875 24C45.1875 35.6828 35.6828 45.1875 24 45.1875Z"
        fill={color}
      />
      <path
        d="M24 26.9292C20.0681 26.9292 16.3703 29.0245 14.3496 32.3974C13.9504 33.0636 14.1669 33.9273 14.8331 34.3264C15.4994 34.7258 16.3629 34.509 16.7621 33.8428C18.2777 31.3133 21.0511 29.7417 24 29.7417C26.9573 29.7417 29.7347 31.3199 31.2484 33.8604C31.5115 34.302 31.9785 34.5472 32.4577 34.5472C32.7025 34.5472 32.9506 34.4831 33.1762 34.3488C33.8434 33.9512 34.0619 33.0881 33.6644 32.4209C31.6462 29.0334 27.943 26.9292 24 26.9292Z"
        fill={color}
      />
      <path
        d="M20.9316 19.0982C21.1831 18.3635 20.7914 17.5639 20.0566 17.3123C19.3217 17.0608 18.5222 17.4526 18.2707 18.1873C17.8101 19.5332 16.5448 20.4375 15.1221 20.4375C13.6985 20.4375 12.4328 19.5323 11.9727 18.1852C11.7216 17.4504 10.9225 17.058 10.1874 17.3091C9.45239 17.5601 9.06023 18.3594 9.3112 19.0943C10.1602 21.5799 12.4954 23.25 15.1221 23.25C17.7469 23.25 20.0816 21.5815 20.9316 19.0982Z"
        fill={color}
      />
      <path
        d="M37.8212 17.3123C37.0868 17.061 36.287 17.4526 36.0353 18.1873C35.5745 19.5332 34.3092 20.4375 32.8866 20.4375C31.4629 20.4375 30.1972 19.5323 29.7371 18.1852C29.486 17.4504 28.6871 17.058 27.9518 17.3091C27.2168 17.5601 26.8246 18.3594 27.0756 19.0943C27.9246 21.5799 30.2599 23.25 32.8866 23.25C35.5113 23.25 37.846 21.5816 38.6962 19.0984C38.9477 18.3635 38.556 17.564 37.8212 17.3123Z"
        fill={color}
      />
    </svg>
  );
}
