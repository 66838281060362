import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useAppContext } from '../App';
import { suggestions } from '../data/suggestions';
import { MoodButton } from './MoodButton';

export type Moods =
  | 'excited'
  | 'happy'
  | 'neutral'
  | 'unhappy'
  | 'exhausted'
  | 'done' // to say that the task was completed
  | 'pending' // to say the task was not yet completed
  | null;

type Props = {
  isOpen: boolean;
};

function MoodModal({ isOpen }: Props) {
  const [mood, setMood] = useState<Moods>(null);
  const [wasTaskCompleted, setWasTaskCompleted] = useState(true);
  const {
    setShouldOpenModal,
    pomodoroCounter,
    modalTodoContext,
    markTaskFinished,
  } = useAppContext();

  useEffect(() => {
    if (isOpen) {
      setMood(null);
    }
  }, [isOpen]);

  const shouldShowMoodChecker = () => {
    return pomodoroCounter !== 0 && pomodoroCounter % 4 === 0;
  };

  const markTaskStatus = (isCompleted: boolean) => {
    markTaskFinished(isCompleted);
    setWasTaskCompleted(isCompleted);
  }

  const getModalStep = () => {
    if (modalTodoContext) {
      return renderFinishedTaskChecker();
    }

    return shouldShowMoodChecker()
      ? renderMoodChecker()
      : renderTaskCompletedMessage();
  };

  const renderFinishedTaskChecker = () => {
    return (
      <div className="flex flex-col justify-center text-center">
        <h2 className="font-bold text-2xl mb-4 text-white">
          Did you finish working on your task?
        </h2>
        {modalTodoContext && (
          <p className="text-base mb-6 text-white">
            "{modalTodoContext?.text}"
          </p>
        )}
        <div style={{ lineHeight: 0 }}>
          <MoodButton
            moodName="pending"
            onClick={() => markTaskStatus(false)}
          />
          <MoodButton moodName="done" onClick={() => markTaskStatus(true)} />
        </div>
      </div>
    );
  };

  const renderMoodChecker = () => {
    if (!mood) {
      return (
        <div className="flex flex-col justify-center text-center">
          <h2 className="font-bold text-2xl mb-4 text-white">
            How are you feeling?
          </h2>
          <p className="text-base mb-8 text-white">
            Tell us how you are feeling after this focus session!
          </p>
          <div style={{ lineHeight: 0 }}>
            <MoodButton moodName="excited" onClick={setMood} />
            <MoodButton moodName="happy" onClick={setMood} />
            <MoodButton moodName="neutral" onClick={setMood} />
            <MoodButton moodName="unhappy" onClick={setMood} />
            <MoodButton moodName="exhausted" onClick={setMood} />
          </div>
        </div>
      );
    }

    return (
      <>
        <div className="flex flex-col justify-center text-center">
          <h2 className="font-bold text-2xl mb-4 text-white">
            {suggestions[mood].title}
          </h2>
          <p className="text-base mb-8 text-white">
            {suggestions[mood].suggestion}
          </p>
          <div style={{ lineHeight: 0 }}>
            <MoodButton moodName={mood} isSelected={true} />
          </div>
        </div>
        <div
          onClick={() => setShouldOpenModal(false)}
          className="mood-modal__close-button"
        />
      </>
    );
  };

  const renderTaskCompletedMessage = () => {
    if (isOpen) {
      setTimeout(() => {
        setShouldOpenModal(false);
      }, 3000);
    }

    return (
      <div className="flex flex-col justify-center text-center">
        <h2 className="font-bold text-2xl mb-4 text-white">
          {wasTaskCompleted ? '🎉 Great work! Pick a new task to work on or take a short break.' : ' 💪 Keep going, you can start this task again!'}
        </h2>
      </div>
    );
  };

  return (
    <Modal
      appElement={document.getElementById('app') ?? undefined}
      isOpen={isOpen}
      contentLabel="Minimal Modal Example"
      className="mood-modal__body"
    >
      {getModalStep()}
    </Modal>
  );
}

export default MoodModal;
