import { useCallback, useEffect, useRef, useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { useAppContext } from '../App';
import { CheckMarkIcon, ErrorIcon, PauseIcon, PlayIcon } from '../icons';
import { TodoItemType } from './TodoItem';

const SECOND_IN_MS = 1000;
const DEFAULT_TIMER_DURATION = 25 * 60 * SECOND_IN_MS;
const FIVE_MINUTES = 5 * 60 * SECOND_IN_MS;
const FIFTEEN_MINUTES = 15 * 60 * SECOND_IN_MS;

const TASK_PROGRESS_COLOR = '#34C759';
const BREAK_PROGRESS_COLOR = '#5AC8FA';

const formatDuration = (duration: number): string => {
  if (duration <= 0) return '00:00';
  const durationInSeconds = Math.floor(duration / 1000);
  const minutes = Math.floor(durationInSeconds / 60);
  const seconds = durationInSeconds % 60;
  const minutesFormatted = `${minutes}`.padStart(2, '0');
  const secondsFormatted = `${seconds}`.padStart(2, '0');
  return `${minutesFormatted}:${secondsFormatted}`;
};

enum TimerStates {
  PAUSED,
  RUNNING,
  STOPPED,
}

type TimerProps = {
  duration?: number;
  shouldShowControls?: boolean;
  todo?: TodoItemType | null;
  onComplete?: (todo: TodoItemType, pomodoroCounter: number) => void;
  onPause?: (duration: number) => void;
  onStop?: (todo: TodoItemType) => void;
  onTaskComplete?: (todo: TodoItemType, pomodoroCounter: number) => void;
};

export const Timer = ({
  duration = DEFAULT_TIMER_DURATION,
  shouldShowControls = true,
  todo,
  onComplete,
  onPause,
  onStop,
  onTaskComplete,
}: TimerProps) => {
  const [durationTime, setDurationTime] = useState(duration);
  const [remainingTime, setRemainingTime] = useState(durationTime);
  const [timerState, setTimerState] = useState(TimerStates.STOPPED);
  const { increasePomodoroCounter, pomodoroCounter, finishedTodos, modalTodoContext } =
    useAppContext();
  const timer = useRef<NodeJS.Timeout>();

  useEffect(() => {
    setRemainingTime(duration);
    setDurationTime(duration);
  }, [duration]);

  const handleTimerCompleted = useCallback(() => {
    setTimerState(TimerStates.STOPPED);
    if (onComplete && todo) {
      onComplete(todo, pomodoroCounter);
      if (todo.type === 'task') {
        increasePomodoroCounter();
      }
    }
  }, [onComplete, todo, pomodoroCounter, increasePomodoroCounter]);

  useEffect(() => {
    if (timerState === TimerStates.RUNNING) {
      const timeTillNextSecond = Math.min(SECOND_IN_MS, remainingTime);

      if (timeTillNextSecond <= 0) {
        handleTimerCompleted();
        return;
      }

      timer.current = setTimeout(() => {
        setRemainingTime(remainingTime - timeTillNextSecond);
      }, timeTillNextSecond);
    }

    return () => {
      if (timer.current) clearTimeout(timer.current);
    };
  }, [remainingTime, timerState, handleTimerCompleted]);

  const handleStartClick = () => {
    setTimerState(TimerStates.RUNNING);
  };

  const handlePauseClick = () => {
    setTimerState(TimerStates.PAUSED);
    onPause?.(remainingTime);
  };

  const handleStopClick = () => {
    setTimerState(TimerStates.STOPPED);
    setRemainingTime(durationTime);
    if (onStop && todo) onStop(todo);
  };

  const handleTaskCompleteClick = () => {
    if (onTaskComplete && todo) {
      onTaskComplete(todo, pomodoroCounter);
      increasePomodoroCounter();
    }
  };

  useEffect(() => {
    if (todo?.type === 'short') {
      setTimerState(TimerStates.STOPPED);
      setRemainingTime(FIVE_MINUTES);
      setDurationTime(FIVE_MINUTES);
    } else if (todo?.type === 'long') {
      setTimerState(TimerStates.STOPPED);
      setRemainingTime(FIFTEEN_MINUTES);
      setDurationTime(FIFTEEN_MINUTES);
    }
    if (todo?.type === 'task') handleStartClick();
  }, [todo]);

  useEffect(() => {
    if (todo && todo?.type !== 'task' && !modalTodoContext) handleStartClick();
  }, [todo, modalTodoContext]);

  return (
    <div className="flex justify-start align-middle flex-col">
      <div
        className="relative"
        style={{ height: 'fit-content', lineHeight: 0 }}
      >
        <CircularProgressbar
          className="mx-auto"
          strokeWidth={2}
          value={((durationTime - remainingTime) / durationTime) * 100}
          styles={{
            root: { height: 360, width: 360 },
            path: {
              stroke:
                todo?.type === 'task'
                  ? TASK_PROGRESS_COLOR
                  : BREAK_PROGRESS_COLOR,
              strokeLinecap: 'round',
            },
            trail: {
              stroke: '#E5E5E5',
            },
          }}
        />
        <div className="absolute flex flex-col justify-center w-full top-1/2 bottom-1/2">
          <div className="text-7xl text-center">
            {formatDuration(remainingTime)}
          </div>
          {shouldShowControls && timerState !== TimerStates.STOPPED && (
            <div
              className="flex flex-row items-center justify-center mt-6"
              style={{
                lineHeight: 0,
                paddingRight: todo?.type !== 'task' ? 48 : 0,
              }}
            >
              <button onClick={handleStopClick} className="mx-2">
                <ErrorIcon />
              </button>

              {timerState === TimerStates.PAUSED && (
                <button onClick={handleStartClick} className="mx-2">
                  <PlayIcon size={64} />
                </button>
              )}
              {timerState === TimerStates.RUNNING && (
                <button onClick={handlePauseClick} className="mx-2">
                  <PauseIcon size={64} />
                </button>
              )}

              {todo?.type === 'task' && (
                <button onClick={handleTaskCompleteClick} className="mx-2">
                  <CheckMarkIcon color="#34C759" />
                </button>
              )}
            </div>
          )}
        </div>
      </div>
      {timerState === TimerStates.STOPPED &&
        !todo &&
        finishedTodos.length === 0 && (
          <p className="text-lg mt-8 px-8 text-center">
            Start working on your first task to get the timer running
          </p>
        )}
      {timerState === TimerStates.STOPPED &&
        !todo &&
        finishedTodos.length > 0 && (
          <p className="text-lg mt-8 px-8 text-center">
            Keep it up! Go on and complete some other tasks
          </p>
        )}
    </div>
  );
};
