import { IconProps } from '.';

export function EmojiExcitedLineIcon({
  color = 'white',
  size = 48,
}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6_244)">
        <path
          d="M40.9705 7.02947C36.4376 2.49647 30.4106 0 24 0C17.5894 0 11.5624 2.49647 7.02947 7.02947C2.49638 11.5624 0 17.5894 0 24C0 30.4106 2.49638 36.4376 7.02947 40.9705C11.5624 45.5035 17.5894 48 24 48C30.4106 48 36.4376 45.5035 40.9705 40.9705C45.5036 36.4376 48 30.4106 48 24C48 17.5894 45.5036 11.5624 40.9705 7.02947ZM24 45.1429C12.3418 45.1429 2.85713 35.6582 2.85713 24C2.85713 12.3418 12.3418 2.85713 24 2.85713C35.6582 2.85713 45.1429 12.3418 45.1429 24C45.1429 35.6582 35.6582 45.1429 24 45.1429Z"
          fill={color}
        />
        {/* <path
          d="M256 512C114.841 512 0 397.159 0 256C0 114.841 114.841 0 256 0C397.159 0 512 114.841 512 256C512 397.159 397.159 512 256 512Z"
          fill="#FFDE46"
        /> */}
        <path
          d="M18.8082 17.6621C16.4688 15.3226 12.6623 15.3228 10.323 17.6621C9.76506 18.2199 9.76506 19.1245 10.323 19.6824C10.881 20.2402 11.7856 20.2402 12.3434 19.6824C13.5689 18.457 15.5628 18.4571 16.7881 19.6824C17.0671 19.9614 17.4326 20.1009 17.7981 20.1009C18.1637 20.1009 18.5294 19.9614 18.8082 19.6824C19.3661 19.1245 19.3661 18.22 18.8082 17.6621Z"
          fill={color}
        />
        <path
          d="M37.6766 17.6621C35.3373 15.3228 31.5307 15.3226 29.1914 17.6621C28.6335 18.2199 28.6335 19.1245 29.1914 19.6824C29.7494 20.2402 30.654 20.2402 31.2118 19.6824C32.4371 18.457 34.431 18.4569 35.6565 19.6824C35.9355 19.9614 36.301 20.1009 36.6665 20.1009C37.0321 20.1009 37.3978 19.9614 37.6766 19.6824C38.2345 19.1245 38.2345 18.22 37.6766 17.6621Z"
          fill={color}
        />
        <path
          d="M36.6667 25.0477H11.3333C10.5443 25.0477 9.90479 25.6873 9.90479 26.4762C9.90479 34.2483 16.2279 40.5714 24 40.5714C31.7721 40.5714 38.0952 34.2483 38.0952 26.4762C38.0952 25.6872 37.4557 25.0477 36.6667 25.0477ZM24 37.7143C18.2871 37.7143 13.5561 33.4297 12.8523 27.9048H35.1478C34.4439 33.4297 29.7129 37.7143 24 37.7143Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_6_244">
          <rect width="48" height="48" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
}
