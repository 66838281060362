import { IconProps } from '.';

export function EmojiHappyFlatIcon({ size = 48 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 48C10.7663 48 0 37.2337 0 24C0 10.7663 10.7663 0 24 0C37.2337 0 48 10.7663 48 24C48 37.2337 37.2337 48 24 48Z"
        fill="#FFDE46"
      />
      <path
        d="M24 0V48C37.2337 48 48 37.2337 48 24C48 10.7663 37.2337 0 24 0Z"
        fill="#FFCD00"
      />
      <path
        d="M10.6421 20.4544C10.4914 20.4544 10.3381 20.4299 10.1874 20.3784C9.45243 20.1274 9.06018 19.3281 9.31124 18.5932C10.1603 16.1075 12.4956 14.4375 15.1222 14.4375C17.7471 14.4375 20.0817 16.106 20.9318 18.5891C21.1834 19.3239 20.7916 20.1234 20.0569 20.3751C19.322 20.6265 18.5225 20.2349 18.2709 19.5001C17.8101 18.1543 16.5448 17.25 15.1222 17.25C13.6986 17.25 12.4329 18.1551 11.9728 19.5023C11.7732 20.0866 11.2269 20.4544 10.6421 20.4544Z"
        fill="#00347B"
      />
      <path
        d="M28.4063 20.4544C28.2555 20.4544 28.1022 20.4299 27.9516 20.3784C27.2166 20.1274 26.8243 19.3281 27.0754 18.5932C27.9246 16.1075 30.2598 14.4375 32.8864 14.4375C35.5112 14.4375 37.8459 16.106 38.696 18.5891C38.9475 19.3239 38.5557 20.1234 37.821 20.3751C37.086 20.6265 36.2866 20.2349 36.0351 19.5001C35.5743 18.1543 34.309 17.25 32.8864 17.25C31.4628 17.25 30.1971 18.1551 29.737 19.5023C29.5374 20.0866 28.9912 20.4544 28.4063 20.4544Z"
        fill="#001737"
      />
      <path
        d="M24 37.2894C20.0682 37.2894 16.3704 35.1941 14.3496 31.8213C13.9504 31.1551 14.1669 30.2915 14.8332 29.8923C15.4996 29.4932 16.3632 29.7098 16.7623 30.3759C18.2779 32.9054 21.0512 34.4769 24.0001 34.4769C26.9574 34.4769 29.7348 32.8987 31.2486 30.3582C31.6461 29.6909 32.5092 29.4721 33.1764 29.8699C33.8436 30.2674 34.0622 31.1306 33.6646 31.7977C31.6463 35.1852 27.9431 37.2894 24 37.2894Z"
        fill="#00347B"
      />
      <path
        d="M33.1762 29.8699C32.5089 29.4721 31.6458 29.6909 31.2484 30.3582C29.7357 32.8969 26.9611 34.4745 24.0061 34.4768V37.2893C27.9468 37.2871 31.6473 35.1834 33.6645 31.7978C34.062 31.1307 33.8434 30.2674 33.1762 29.8699Z"
        fill="#001737"
      />
    </svg>
  );
}
