import { useCallback, useEffect, useRef, useState } from 'react';
import { CheckMarkIcon, PlayIcon } from '../icons';

export type TodoItemType = {
  id: string;
  isCompleted?: boolean;
  text: string;
  type: 'task' | 'short' | 'long';
};

type Props = {
  todo: TodoItemType | null;
  updateTodo?: (todoItem: TodoItemType) => void;
  removeTodo?: (todoItem: TodoItemType) => void;
  startItem?: (todoItem: TodoItemType) => void;
  stopItem?: (todoItem: TodoItemType) => void;
  hasCurrentItem?: boolean;
};

function TodoItem(props: Props) {
  const inputRef = useRef<HTMLInputElement>(null);
  const { todo, updateTodo, startItem, hasCurrentItem } = props;
  const todoText = todo?.text || '';

  const [isEditting, setIsEditting] = useState<boolean>(false);
  const [newValue, setNewValue] = useState<string>(todoText);

  useEffect(() => {
    if (isEditting) {
      inputRef.current?.focus();
    }
  }, [isEditting]);

  const toggleEditMode = useCallback(() => {
    setIsEditting(!isEditting);
  }, [isEditting]);

  const saveTodo = useCallback(() => {
    if (updateTodo && todo) {
      updateTodo({
        ...todo,
        text: newValue,
      });
      toggleEditMode();
    }
  }, [newValue, updateTodo, todo, toggleEditMode]);

  const handleInputKeydown = useCallback(
    (e) => {
      if (e.key === 'Enter' && newValue) saveTodo();
    },
    [newValue, saveTodo]
  );

  if (!todo) {
    return null;
  }

  return (
    <div className="border-b py-2 flex flex-row items-center">
      <div className="flex-grow flex-shrink overflow-hidden text-left">
        {isEditting ? (
          <input
            className="focus:outline-none w-full"
            onChange={(e) => setNewValue(e.target.value)}
            onKeyDown={handleInputKeydown}
            placeholder="Add A Task"
            ref={inputRef}
            type="text"
            value={newValue}
          />
        ) : (
          <span className="break-all">{todoText}</span>
        )}
      </div>
      <div
        className="flex flex-row flex-shrink-0 ml-4 self-start"
        style={{ lineHeight: 0 }}
      >
        {updateTodo && !isEditting && (
          <button onClick={toggleEditMode}>Edit</button>
        )}
        {updateTodo && isEditting && <button onClick={saveTodo}>Save</button>}

        {startItem && !hasCurrentItem && (
          <button onClick={() => startItem(todo)} className="ml-2">
            <PlayIcon />
          </button>
        )}

        {todo.isCompleted && (
          <span className="ml-2">
            <CheckMarkIcon color="#AAAAAA" />
          </span>
        )}
      </div>
    </div>
  );
}

export default TodoItem;
