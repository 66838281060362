import { IconProps } from '.';

export function EmojiExcitedFlatIcon({ size = 48 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 48C10.7663 48 0 37.2337 0 24C0 10.7663 10.7663 0 24 0C37.2337 0 48 10.7663 48 24C48 37.2337 37.2337 48 24 48Z"
        fill="#FFDE46"
      />
      <path
        d="M24 0V48C37.2337 48 48 37.2337 48 24C48 10.7663 37.2337 0 24 0Z"
        fill="#FFCD00"
      />
      <path
        d="M18.4817 20.1846C19.8178 18.8485 19.8178 16.6822 18.4817 15.3461C17.1455 14.01 14.9793 14.01 13.6431 15.3461C12.307 16.6822 12.307 18.8485 13.6431 20.1846C14.9793 21.5208 17.1455 21.5208 18.4817 20.1846Z"
        fill="#00429D"
      />
      <path
        d="M16.0662 22.5937C13.4039 22.5937 11.238 20.4278 11.238 17.7656C11.238 15.1034 13.4039 12.9375 16.0662 12.9375C18.7284 12.9375 20.8943 15.1034 20.8943 17.7656C20.8943 20.4278 18.7284 22.5937 16.0662 22.5937ZM16.0662 15.75C14.9548 15.75 14.0505 16.6542 14.0505 17.7656C14.0505 18.877 14.9548 19.7812 16.0662 19.7812C17.1776 19.7812 18.0818 18.877 18.0818 17.7656C18.0818 16.6542 17.1776 15.75 16.0662 15.75Z"
        fill="#FFF4F4"
      />
      <path
        d="M31.9341 21.1875C33.8239 21.1875 35.356 19.6555 35.356 17.7656C35.356 15.8758 33.8239 14.3438 31.9341 14.3438C30.0442 14.3438 28.5122 15.8758 28.5122 17.7656C28.5122 19.6555 30.0442 21.1875 31.9341 21.1875Z"
        fill="#00347B"
      />
      <path
        d="M31.9341 22.5937C29.2719 22.5937 27.106 20.4278 27.106 17.7656C27.106 15.1034 29.2719 12.9375 31.9341 12.9375C34.5963 12.9375 36.7622 15.1034 36.7622 17.7656C36.7622 20.4278 34.5963 22.5937 31.9341 22.5937ZM31.9341 15.75C30.8227 15.75 29.9185 16.6542 29.9185 17.7656C29.9185 18.877 30.8227 19.7812 31.9341 19.7812C33.0455 19.7812 33.9497 18.877 33.9497 17.7656C33.9497 16.6542 33.0455 15.75 31.9341 15.75Z"
        fill="#F6EFEA"
      />
      <path
        d="M24.0001 36.8438C21.2859 36.8438 18.7817 39.9111 16.7814 38.3705C13.965 36.2015 12.1472 32.7967 12.1472 28.9751V28.7813C12.1472 28.3929 12.462 28.0781 12.8503 28.0781H35.15C35.5383 28.0781 35.8531 28.3929 35.8531 28.7813V28.9751C35.8531 32.7966 34.0353 36.2014 31.219 38.3704C29.2185 39.9111 26.7144 36.8438 24.0001 36.8438Z"
        fill="#00347B"
      />
      <path
        d="M35.1498 28.0781H24V36.8438C26.7142 36.8438 29.2184 39.9111 31.2187 38.3705C34.0351 36.2015 35.8529 32.7967 35.8529 28.9752V28.7813C35.8529 28.3929 35.5381 28.0781 35.1498 28.0781Z"
        fill="#001737"
      />
      <path
        d="M16.7761 38.3656C18.7774 39.9087 21.2832 40.8281 23.9999 40.8281C26.7167 40.8281 29.2225 39.9087 31.2238 38.3656C30.6766 35.6976 27.651 33.6562 23.9999 33.6562C20.3488 33.6562 17.3232 35.6976 16.7761 38.3656Z"
        fill="#FF3F5C"
      />
      <path
        d="M24 40.8281C26.7168 40.8281 29.2224 39.9087 31.2237 38.3656C30.6767 35.6976 27.6511 33.6562 24 33.6562V40.8281Z"
        fill="#D20763"
      />
    </svg>
  );
}
