import { useLocalStorage } from './useLocalStorage';

export function usePomodoro() {
  const [pomodoroCounter, setPomodoroCounter] = useLocalStorage(
    'pomodoroCounter',
    0
  );

  const increasePomodoroCounter = () => {
    setPomodoroCounter(pomodoroCounter + 1);
  };

  return {
    increasePomodoroCounter,
    pomodoroCounter,
  };
}
