import { createContext, useContext } from 'react';
import './App.css';

import Lanes from './components/Lanes';
import { Timer } from './components/Timer';
import { useTodos } from './hooks/useTodos';
import { usePomodoro } from './hooks/usePomodoro';
import { TodoItemType } from './components/TodoItem';
import MoodModal from './components/MoodModal';
import { Header } from './components/Header';

type AppContextType = {
  todos: TodoItemType[];
  addTodo: (todoItem: TodoItemType) => void;
  updateTodo: (todoItem: TodoItemType) => void;
  removeTodo: (todoItem: TodoItemType) => void;
  finishedTodos: TodoItemType[];
  currentTodo: TodoItemType | null;
  startItem: (todoItem: TodoItemType) => void;
  stopItem: (todoItem: TodoItemType) => void;
  finishItem: (todoItem: TodoItemType, pomodoroCounter: number) => void;
  setShouldOpenModal: (isOpen: boolean) => void;
  markTaskFinished: (finishedTask: boolean) => void;
  increasePomodoroCounter: () => void;
  pomodoroCounter: number;
  completeTimer:(todoItem: TodoItemType, pomodoroCounter: number) => void;
  modalTodoContext: TodoItemType | null;
};

export const AppContext = createContext<AppContextType>({
  todos: [],
  addTodo: (todoItem: TodoItemType) => {},
  updateTodo: (todoItem: TodoItemType) => {},
  removeTodo: (todoItem: TodoItemType) => {},
  finishedTodos: [],
  currentTodo: null,
  startItem: (todoItem: TodoItemType) => {},
  stopItem: (todoItem: TodoItemType) => {},
  finishItem: (todoItem: TodoItemType, pomodoroCounter: number) => {},
  setShouldOpenModal: (isOpen: boolean) => {},
  markTaskFinished: (finishedTask: boolean) => {},
  increasePomodoroCounter: () => {},
  pomodoroCounter: 0,
  completeTimer:(todoItem: TodoItemType, pomodoroCounter: number) => {},
  modalTodoContext: null,
});

export function useAppContext() {
  return useContext(AppContext);
}

type AppState = 'editing' | 'working';

function App() {
  const useTodosObj = useTodos();
  const usePomodoroObj = usePomodoro();

  let appState: AppState = 'editing';
  if (useTodosObj.currentTodo) {
    appState = 'working';
  }

  const timer = (
    <Timer
      todo={useTodosObj.currentTodo}
      onStop={useTodosObj.stopItem}
      onTaskComplete={useTodosObj.finishItem}
      onComplete={useTodosObj.completeTimer}
    />
  );

  return (
    <AppContext.Provider value={{ ...useTodosObj, ...usePomodoroObj }}>
      <div className="App" id="app">
        <Header />
        {appState === 'editing' && (
          <main className="main container mx-auto">
            {timer}
            <Lanes />
          </main>
        )}
        {appState === 'working' && (
          <main className="main__working container mx-auto">
            <p className="px-24 font-bold text-center w-3/4 break-all mb-8">
              {useTodosObj.currentTodo?.text}
            </p>
            {timer}
          </main>
        )}
        <MoodModal isOpen={useTodosObj.shouldOpenModal} />
      </div>
    </AppContext.Provider>
  );
}

export default App;
