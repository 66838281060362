export function Header() {
  return (
    <div
      className="text-center text-white font-bold py-3 text-2xl mb-24"
      style={{ backgroundColor: '#5AC8FA' }}
    >
      GetPomodoro
    </div>
  );
}
