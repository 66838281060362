import { IconProps } from '.';

export function EmojiExhaustedFlatIcon({ size = 48 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.2187 21.1425H16.7811C16.0045 21.1425 15.3749 20.5129 15.3749 19.7363V16.3125C15.3749 12.1588 12.5903 8.42639 8.60342 7.23614C7.93442 7.03645 7.5133 6.37617 7.61427 5.68542C7.71514 4.99458 8.30764 4.48242 9.00571 4.48242H38.9943C39.6923 4.48242 40.2848 4.99458 40.3858 5.68542C40.4867 6.37617 40.0656 7.03645 39.3966 7.23614C35.4096 8.42639 32.625 12.1588 32.625 16.3125V19.7363C32.625 20.5129 31.9954 21.1425 31.2187 21.1425Z"
        fill="#F6EFEA"
      />
      <path
        d="M40.3858 5.68542C40.2848 4.99458 39.6924 4.48242 38.9942 4.48242H23.9998V21.1425H31.2188C31.9954 21.1425 32.625 20.5129 32.625 19.7363V16.3125C32.625 12.1588 35.4096 8.42639 39.3965 7.23614C40.0655 7.03645 40.4866 6.37617 40.3858 5.68542Z"
        fill="#EEE1DC"
      />
      <path
        d="M24 48C17.5894 48 11.5625 45.5036 7.02947 40.9707C2.49647 36.4376 0 30.4107 0 24C0 22.4815 0.14475 20.9552 0.430125 19.464C0.50625 19.0661 0.750281 18.7203 1.09969 18.5154C1.44909 18.3104 1.86994 18.266 2.25431 18.3936L7.05459 19.9871L12.2599 18.2591C12.5475 18.1636 12.8584 18.1636 13.1459 18.2591L18.3513 19.9871L23.5566 18.2591C23.8442 18.1636 24.155 18.1636 24.4426 18.2591L29.6479 19.9871L34.8532 18.2591C35.1408 18.1636 35.4516 18.1636 35.7392 18.2591L40.9445 19.9871L45.7453 18.3934C46.1298 18.2659 46.5506 18.3102 46.8999 18.5152C47.2492 18.7202 47.4934 19.0659 47.5695 19.4638C47.8552 20.9553 48 22.4817 48 24C48 30.4107 45.5035 36.4376 40.9706 40.9707C36.4375 45.5036 30.4106 48 24 48Z"
        fill="#FFDE46"
      />
      <path
        d="M47.5697 19.4638C47.4936 19.0659 47.2496 18.7201 46.9002 18.5152C46.5508 18.3103 46.1299 18.2659 45.7455 18.3934L40.9447 19.9871L35.7394 18.2591C35.4518 18.1636 35.141 18.1636 34.8534 18.2591L29.6481 19.9871L24.4428 18.2591C24.299 18.2114 24.1494 18.1875 23.9998 18.1875V48H24C30.4107 48 36.4376 45.5036 40.9707 40.9707C45.5035 36.4376 47.9999 30.4107 47.9999 24C47.9999 22.4817 47.8552 20.9553 47.5697 19.4638Z"
        fill="#FFCD00"
      />
      <path
        d="M24.1472 12.7328C22.8445 12.7328 21.5747 12.2197 20.5715 11.2879C20.3159 11.0506 20.0221 11.0218 19.744 11.2073C19.0944 11.6403 18.357 11.8691 17.6115 11.8691C16.53 11.8691 15.4879 11.3913 14.677 10.5236C14.5432 10.3805 14.2627 10.1672 13.8886 10.351C13.3969 10.5923 12.8735 10.7148 12.3327 10.7148C11.5905 10.7148 10.8692 10.4792 10.2469 10.0335C9.99534 9.85331 9.72956 9.85322 9.47822 10.0334C8.85581 10.4792 8.1345 10.7148 7.39219 10.7148C5.25469 10.7148 3.51562 8.76412 3.51562 6.36647C3.51562 3.96881 5.25469 2.01816 7.39219 2.01816C8.13469 2.01816 8.856 2.25375 9.47822 2.69953C9.72956 2.87953 9.99534 2.87963 10.2469 2.69944C10.869 2.25375 11.5903 2.01816 12.3328 2.01816C12.8737 2.01816 13.3973 2.14059 13.8887 2.382C14.2628 2.56566 14.5432 2.35266 14.677 2.20931C15.4878 1.34166 16.53 0.863812 17.6115 0.863812C18.3571 0.863812 19.0945 1.09256 19.744 1.5255C20.0222 1.71075 20.316 1.68216 20.5715 1.44497C21.5749 0.513188 22.8447 0 24.1472 0C25.4574 0 26.7331 0.518531 27.7392 1.46025C27.9966 1.701 28.2928 1.72959 28.573 1.54031C29.2283 1.09763 29.9735 0.863719 30.7276 0.863719C31.8091 0.863719 32.8513 1.34156 33.6622 2.20922C33.8258 2.38434 34.0954 2.55609 34.4506 2.38191C34.9419 2.14059 35.4654 2.01816 36.0064 2.01816C36.6907 2.01816 37.3632 2.22038 37.9512 2.60306C38.1908 2.75869 38.4234 2.75878 38.663 2.60297C39.251 2.22038 39.9235 2.01816 40.6078 2.01816C42.7454 2.01816 44.4845 3.96881 44.4845 6.36647C44.4845 8.76412 42.7454 10.7148 40.6078 10.7148C39.9236 10.7148 39.2511 10.5126 38.663 10.1298C38.4234 9.97406 38.1906 9.97416 37.9512 10.13C37.363 10.5126 36.6905 10.7148 36.0064 10.7148C35.4656 10.7148 34.9422 10.5923 34.4506 10.351C34.0953 10.1767 33.8258 10.3485 33.6623 10.5235C32.8515 11.3913 31.8093 11.8691 30.7276 11.8691C29.9734 11.8691 29.2284 11.6352 28.5731 11.1927C28.2929 11.0033 27.9968 11.0317 27.7391 11.2728C26.7331 12.2143 25.4574 12.7328 24.1472 12.7328Z"
        fill="#FFF4F4"
      />
      <path
        d="M24 43.0784C21.3894 43.0784 19.2656 40.7436 19.2656 37.8737C19.2656 35.0037 21.3894 32.6689 24 32.6689C26.6106 32.6689 28.7344 35.0037 28.7344 37.8737C28.7344 40.7436 26.6106 43.0784 24 43.0784Z"
        fill="#00347B"
      />
      <path
        d="M18.4807 29.0902C19.8168 27.754 19.8168 25.5878 18.4807 24.2516C17.1446 22.9155 14.9783 22.9155 13.6422 24.2516C12.306 25.5878 12.306 27.754 13.6422 29.0902C14.9783 30.4263 17.1446 30.4263 18.4807 29.0902Z"
        fill="#00347B"
      />
      <path
        d="M16.0662 31.5C13.4039 31.5 11.238 29.3341 11.238 26.6719C11.238 24.0097 13.4039 21.8438 16.0662 21.8438C18.7284 21.8438 20.8943 24.0097 20.8943 26.6719C20.8943 29.3341 18.7284 31.5 16.0662 31.5ZM16.0662 24.6562C14.9548 24.6562 14.0505 25.5605 14.0505 26.6719C14.0505 27.7833 14.9548 28.6875 16.0662 28.6875C17.1776 28.6875 18.0818 27.7833 18.0818 26.6719C18.0818 25.5605 17.1776 24.6562 16.0662 24.6562Z"
        fill="#FFF4F4"
      />
      <path
        d="M31.9341 30.0938C33.8239 30.0938 35.356 28.5617 35.356 26.6719C35.356 24.782 33.8239 23.25 31.9341 23.25C30.0442 23.25 28.5122 24.782 28.5122 26.6719C28.5122 28.5617 30.0442 30.0938 31.9341 30.0938Z"
        fill="#001737"
      />
      <path
        d="M31.9341 31.5C29.2719 31.5 27.106 29.3341 27.106 26.6719C27.106 24.0097 29.2719 21.8438 31.9341 21.8438C34.5963 21.8438 36.7622 24.0097 36.7622 26.6719C36.7622 29.3341 34.5963 31.5 31.9341 31.5ZM31.9341 24.6562C30.8227 24.6562 29.9185 25.5605 29.9185 26.6719C29.9185 27.7833 30.8227 28.6875 31.9341 28.6875C33.0455 28.6875 33.9497 27.7833 33.9497 26.6719C33.9497 25.5605 33.0455 24.6562 31.9341 24.6562Z"
        fill="#F6EFEA"
      />
      <path
        d="M24 32.6689C23.9999 32.6689 23.9998 32.6689 23.9998 32.6689V43.0783H24C26.6106 43.0783 28.7344 40.7435 28.7344 37.8736C28.7344 35.0036 26.6106 32.6689 24 32.6689Z"
        fill="#001737"
      />
      <path
        d="M40.6078 2.01816C39.9235 2.01816 39.2509 2.22038 38.6629 2.60297C38.4234 2.75878 38.1907 2.75869 37.9512 2.60306C37.3632 2.22038 36.6906 2.01816 36.0063 2.01816C35.4654 2.01816 34.9419 2.14059 34.4505 2.38191C34.0953 2.55609 33.8257 2.38434 33.6621 2.20922C32.8514 1.34156 31.809 0.863719 30.7275 0.863719C29.9733 0.863719 29.2283 1.09763 28.573 1.54031C28.2929 1.72959 27.9966 1.701 27.7392 1.46025C26.733 0.518531 25.4574 0 24.1471 0C24.0979 0 24.0489 0.00215625 23.9998 0.00365625V12.7293C24.0489 12.7308 24.0979 12.7329 24.1471 12.7329C25.4574 12.7329 26.733 12.2144 27.7391 11.2729C27.9967 11.0318 28.2928 11.0034 28.5731 11.1928C29.2284 11.6353 29.9734 11.8692 30.7275 11.8692C31.8092 11.8692 32.8514 11.3914 33.6622 10.5236C33.8257 10.3486 34.0952 10.1768 34.4505 10.3511C34.9422 10.5924 35.4656 10.7149 36.0063 10.7149C36.6904 10.7149 37.363 10.5127 37.9512 10.1301C38.1905 9.97425 38.4234 9.97416 38.6629 10.1299C39.251 10.5127 39.9236 10.7149 40.6078 10.7149C42.7454 10.7149 44.4844 8.76422 44.4844 6.36656C44.4844 3.96891 42.7454 2.01816 40.6078 2.01816Z"
        fill="#F6EFEA"
      />
    </svg>
  );
}
