import { IconProps } from '.';

export function EmojiHappyLineIcon({ color = 'white', size = 48 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 0C10.7663 0 0 10.7663 0 24C0 37.2337 10.7663 48 24 48C37.2337 48 48 37.2337 48 24C48 10.7663 37.2337 0 24 0ZM24 45.1875C12.3172 45.1875 2.8125 35.6828 2.8125 24C2.8125 12.3172 12.3172 2.8125 24 2.8125C35.6828 2.8125 45.1875 12.3172 45.1875 24C45.1875 35.6828 35.6828 45.1875 24 45.1875Z"
        fill={color}
      />
      <path
        d="M18.2711 19.5001C18.5226 20.2349 19.3221 20.6265 20.057 20.3751C20.7917 20.1234 21.1835 19.3239 20.932 18.5891C20.0819 16.106 17.7471 14.4375 15.1224 14.4375C12.4957 14.4375 10.1606 16.1075 9.31148 18.5932C9.06042 19.3281 9.45267 20.1274 10.1877 20.3784C10.3382 20.4299 10.4916 20.4544 10.6424 20.4544C11.2272 20.4544 11.7734 20.0866 11.973 19.5023C12.4332 18.1552 13.6989 17.2501 15.1225 17.2501C16.545 17.25 17.8103 18.1543 18.2711 19.5001Z"
        fill={color}
      />
      <path
        d="M32.8863 14.4375C30.2596 14.4375 27.9245 16.1075 27.0754 18.5932C26.8243 19.3281 27.2166 20.1274 27.9516 20.3784C28.1021 20.4299 28.2555 20.4544 28.4063 20.4544C28.9911 20.4544 29.5373 20.0866 29.737 19.5023C30.1971 18.1552 31.4628 17.2501 32.8864 17.2501C34.309 17.2501 35.5743 18.1544 36.0351 19.5002C36.2866 20.235 37.086 20.6266 37.821 20.3752C38.5557 20.1235 38.9475 19.324 38.696 18.5892C37.8458 16.106 35.5111 14.4375 32.8863 14.4375Z"
        fill={color}
      />
      <path
        d="M33.1761 29.8701C32.5089 29.4723 31.6457 29.6911 31.2483 30.3583C29.7346 32.8988 26.9572 34.477 23.9999 34.477C21.051 34.477 18.2775 32.9056 16.762 30.376C16.3629 29.7099 15.4994 29.4934 14.8329 29.8925C14.1667 30.2917 13.9501 31.1553 14.3493 31.8215C16.3701 35.1943 20.068 37.2895 23.9998 37.2895C27.9428 37.2895 31.646 35.1853 33.6643 31.7979C34.062 31.1308 33.8433 30.2676 33.1761 29.8701Z"
        fill={color}
      />
    </svg>
  );
}
