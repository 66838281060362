type Suggestion = {
  [x: string]: {
    title: string;
    suggestion: string;
  };
};

export const suggestions: Suggestion = {
  excited: {
    title: 'That’s amazing!',
    suggestion: 'Finish your break and keep going!',
  },
  happy: {
    title: 'Happy you are happy!',
    suggestion: 'Finish your break and keep going!',
  },
  neutral: {
    title: 'That’s ok...',
    suggestion:
      'Close your eyes and focus on your breath for one minute. It will make things better.',
  },
  unhappy: {
    title: 'It’s time for a longer break!',
    suggestion:
      'Take a 20-25 minute break and meditate or go for a walk. It should help you regain focus.',
  },
  exhausted: {
    title: 'It’s time to stop working!',
    suggestion:
      'Stop and take as much time as you need. You’ll be much more efficient if you do this.',
  },
};
