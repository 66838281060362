import { IconProps } from '.';

export function EmojiNeutralLineIcon({
  color = 'white',
  size = 48,
}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 0C10.7663 0 0 10.7663 0 24C0 37.2337 10.7663 48 24 48C37.2337 48 48 37.2337 48 24C48 10.7663 37.2337 0 24 0ZM24 45.1875C12.3172 45.1875 2.8125 35.6828 2.8125 24C2.8125 12.3172 12.3172 2.8125 24 2.8125C35.6828 2.8125 45.1875 12.3172 45.1875 24C45.1875 35.6828 35.6828 45.1875 24 45.1875Z"
        fill={color}
      />
      <path
        d="M20.8943 17.7656C20.8943 15.1034 18.7284 12.9375 16.0662 12.9375C13.4039 12.9375 11.238 15.1034 11.238 17.7656C11.238 20.4278 13.4039 22.5938 16.0662 22.5938C18.7284 22.5938 20.8943 20.4278 20.8943 17.7656ZM14.0505 17.7656C14.0505 16.6542 14.9548 15.75 16.0662 15.75C17.1776 15.75 18.0818 16.6542 18.0818 17.7656C18.0818 18.877 17.1776 19.7812 16.0662 19.7812C14.9548 19.7812 14.0505 18.877 14.0505 17.7656Z"
        fill={color}
      />
      <path
        d="M31.9341 12.9375C29.2719 12.9375 27.106 15.1034 27.106 17.7656C27.106 20.4278 29.2719 22.5938 31.9341 22.5938C34.5963 22.5938 36.7622 20.4278 36.7622 17.7656C36.7622 15.1034 34.5963 12.9375 31.9341 12.9375ZM31.9341 19.7812C30.8227 19.7812 29.9185 18.877 29.9185 17.7656C29.9185 16.6542 30.8227 15.75 31.9341 15.75C33.0455 15.75 33.9497 16.6542 33.9497 17.7656C33.9497 18.877 33.0455 19.7812 31.9341 19.7812Z"
        fill={color}
      />
      <path
        d="M32.4375 31.6875H15.5625C14.7859 31.6875 14.1562 32.3171 14.1562 33.0938C14.1562 33.8704 14.7859 34.5 15.5625 34.5H32.4375C33.2141 34.5 33.8438 33.8704 33.8438 33.0938C33.8438 32.3171 33.2141 31.6875 32.4375 31.6875Z"
        fill={color}
      />
    </svg>
  );
}
