import { TodoItemType } from '../components/TodoItem';
import { useLocalStorage } from './useLocalStorage';

export function useTodos() {
  const [todos, setTodos] = useLocalStorage<Array<TodoItemType>>('todos', []);
  const [finishedTodos, setFinishedTodos] = useLocalStorage<
    Array<TodoItemType>
  >('finishedTodos', []);
  const [currentTodo, setCurrentTodo] = useLocalStorage<TodoItemType | null>(
    'currentTodo',
    null
  );
  const [shouldOpenModal, setShouldOpenModal] = useLocalStorage(
    'shouldOpenModal',
    false
  );

  const [modalTodoContext, setModalTodoContext] =
    useLocalStorage<TodoItemType | null>('modalTodoContext', null);

  const addTodo = (todoItem: TodoItemType) => {
    setTodos([...todos, todoItem]);
  };

  const addTodoBeginning = (todoItem: TodoItemType) => {
    setTodos([todoItem, ...todos]);
  };

  const updateTodo = (todoItem: TodoItemType) => {
    const newTodos: TodoItemType[] = todos.map((todo: TodoItemType) =>
      todo.id === todoItem.id ? todoItem : todo
    );
    setTodos(newTodos);
  };

  const removeTodo = (todoItem: TodoItemType) => {
    const removeArr = [...todos].filter((todo) => todo.id !== todoItem.id);
    setTodos(removeArr);
  };

  const startItem = (todoItem: TodoItemType) => {
    setCurrentTodo(todoItem);
    removeTodo(todoItem);
  };

  const stopItem = (todoItem: TodoItemType) => {
    setCurrentTodo(null);

    if (todoItem.type === 'task') {
      addTodoBeginning(todoItem);
    }
  };

  const finishItem = (todoItem: TodoItemType, pomodoroCounter: number) => {
    const nextTodo = getNextTodo(todoItem, pomodoroCounter);
    setCurrentTodo(nextTodo);

    if (todoItem?.type === 'task') {
      setFinishedTodos([{ ...todoItem, isCompleted: true }, ...finishedTodos]);
      setShouldOpenModal(true);
    }
  };

  const completeTimer = (todoItem: TodoItemType, pomodoroCounter: number) => {
    const nextTodo = getNextTodo(todoItem, pomodoroCounter);
    setCurrentTodo(nextTodo);

    if (todoItem?.type === 'task') {
      setModalTodoContext(todoItem);
      setShouldOpenModal(true);
    }
  };

  const markTaskFinished = (finishedTask: boolean) => {
    if (modalTodoContext) {
      if (finishedTask) {
        setFinishedTodos([
          { ...modalTodoContext, isCompleted: true },
          ...finishedTodos,
        ]);
      } else {
        addTodoBeginning(modalTodoContext);
      }

      setModalTodoContext(null);
    }
  };

  const getNextTodo = (
    todoItem: TodoItemType,
    pomodoroCounter: number
  ): TodoItemType | null => {
    if (todoItem.type === 'task') {
      if ((pomodoroCounter + 1) % 4 === 0) {
        return { id: 'long', text: 'Long Break', type: 'long' };
      } else {
        return { id: 'short', text: 'Short Break', type: 'short' };
      }
    } else {
      return null;
    }
  };

  return {
    todos,
    addTodo,
    updateTodo,
    removeTodo,
    finishedTodos,
    currentTodo,
    startItem,
    stopItem,
    finishItem,
    shouldOpenModal,
    setShouldOpenModal,
    markTaskFinished,
    completeTimer,
    modalTodoContext,
  };
}
