import { IconProps } from '.';

export function EmojiUnhappyFlatIcon({ size = 48 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 48C10.7663 48 0 37.2337 0 24C0 10.7663 10.7663 0 24 0C37.2337 0 48 10.7663 48 24C48 37.2337 37.2337 48 24 48Z"
        fill="#FFDE46"
      />
      <path
        d="M24 0V48C37.2337 48 48 37.2337 48 24C48 10.7663 37.2337 0 24 0Z"
        fill="#FFCD00"
      />
      <path
        d="M32.4578 34.5471C31.9785 34.5471 31.5114 34.3021 31.2484 33.8604C29.7347 31.3199 26.9573 29.7417 24 29.7417C21.0511 29.7417 18.2777 31.3131 16.7621 33.8428C16.3629 34.509 15.4994 34.7257 14.8331 34.3264C14.1669 33.9273 13.9504 33.0635 14.3496 32.3974C16.3704 29.0245 20.0681 26.9292 24 26.9292C27.943 26.9292 31.6463 29.0334 33.6645 32.4208C34.062 33.088 33.8435 33.9511 33.1763 34.3487C32.9506 34.4832 32.7025 34.5471 32.4578 34.5471Z"
        fill="#00347B"
      />
      <path
        d="M15.1223 23.2497C12.4956 23.2497 10.1604 21.5797 9.31148 19.0941C9.06042 18.3591 9.45267 17.5597 10.1877 17.3088C10.9228 17.0577 11.7219 17.4502 11.973 18.185C12.4331 19.5322 13.6988 20.4372 15.1224 20.4372C16.545 20.4372 17.8104 19.5329 18.2711 18.1871C18.5226 17.4524 19.3221 17.0605 20.057 17.3122C20.7917 17.5637 21.1835 18.3633 20.932 19.098C20.0818 21.5812 17.7471 23.2497 15.1223 23.2497Z"
        fill="#00347B"
      />
      <path
        d="M32.8866 23.2497C30.2598 23.2497 27.9245 21.5797 27.0756 19.0941C26.8246 18.3591 27.2168 17.5597 27.9518 17.3088C28.6871 17.0577 29.4861 17.4502 29.7371 18.185C30.1972 19.5322 31.463 20.4372 32.8866 20.4372C34.3092 20.4372 35.5746 19.5329 36.0353 18.1871C36.2871 17.4524 37.0868 17.0608 37.8213 17.3122C38.5561 17.5638 38.9479 18.3633 38.6962 19.0981C37.8459 21.5812 35.5113 23.2497 32.8866 23.2497Z"
        fill="#001737"
      />
      <path
        d="M33.6645 32.4208C31.6463 29.0334 27.943 26.9292 24 26.9292V29.7417C26.9573 29.7417 29.7347 31.3199 31.2484 33.8604C31.5115 34.302 31.9785 34.5471 32.4578 34.5471C32.7025 34.5471 32.9506 34.4831 33.1762 34.3488C33.8435 33.9512 34.062 33.088 33.6645 32.4208Z"
        fill="#001737"
      />
    </svg>
  );
}
